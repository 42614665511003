import React, { FC, useState, useEffect } from 'react'
import styled from 'styled-components'
import Dropzone from 'react-dropzone'
import { observer } from 'mobx-react'
import { Button, Dialog, DialogActions, DialogContent, IconButton, Typography, Tooltip, Switch } from '@material-ui/core'
import { Send } from '@material-ui/icons'
import { Close } from '@material-ui/icons'
import { Icon, Space, theme, Text } from 'ui'
import useStore from 'store'
import { MessageDialogMode } from '../../utils/types'
import { userIsSuperAdmin, userIsDirectorOrOperator } from '../../utils/roles'
import { useTranslation } from 'react-i18next'
import { GeneralInput } from 'ui/Input/Input'
import MessagesDateTimeAccordion from './MessagesDateTimeAccordion'
import SurveyList from 'components/Messages/Survey/Survey'
import PdfPreview from './PdfPreview'
import MessagesHeader from 'components/Messages/MessagesHeader'
import WarningDialog from 'components/WarningDialog'
import { GroupType } from 'utils/types'

// import style manually
import 'react-markdown-editor-lite/lib/index.css';
import RichTextEditor from './RichTextEditor'
import VideoViewer from './VideoViewer'
import videoDefaultThumbnail from 'assets/video_placeholder01.svg'

const MAX_PDF_SIZE = 2000000
const MAX_VIDEO_SIZE = 30000000

export enum DeliveryType {
  ImmediateWeek = 'immediateWeek',
  Immediate2Weeks = 'immediate2Weeks',
  ImmediateMonth = 'immediateMonth',
  ImmediateYear = 'immediateYear',
  ImmediateNoExpiry = 'immediateNoExpiry',
  CustomDateTime = 'customDateTime'
}

interface Props { }

const StyledDialog = styled(Dialog)`
  align-self: center;
  justify-self: center;
  .MuiDialog-paperScrollPaper {
  /* min-height: 70vh;
  width:1000px; */
  max-height: 85vh;
  border:1px solid white;
  }
`
const DropArea = styled.div`
flex:1;
  padding: 1rem;
  border-radius: 4px;
  color: ${theme.color.lightBlueText};
  background: ${p => p.color + '1A'};
  border: 1px dashed ${theme.color.lightBlueText};
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    text-align: center;
  }
`

const DropzoneIcon = styled(Icon)`
  margin: 1rem 0.4rem;
`

const SendIcon = styled(Send)`
  body[dir='rtl'] & {
    transform: scaleX(-1)
  }
`

const ConfirmButton = styled(Button)`
  &.MuiButtonBase-root {
    font-size: ${theme.fontSize.large};
    font-weight: 500;
    min-width: 140px;
    height: 50px;
    margin: 0.5rem;
    background: ${theme.color.confirmButton};
    &:hover {
      background: ${theme.color.darkBlue};
    }
    color: ${theme.color.white};
    &.Mui-disabled {
      opacity: 0.5;
    }
  }
`
const DropAreaContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
`
const DropAreaIconsContainer = styled.div`
  display: flex;
  flex-direction: row;
`
const Container = styled.div`
 display:flex;
 justify-content:center;
 align-items:center;
 position:relative;
`
const StyledButton = styled(IconButton)`
position: absolute;
    /* top: 30px; */
    left: 10px;
    top:0;
    z-index: 100;
    color:white;
`
const StyledImage = styled.img`
  height:100%;
  width:100%;
  object-fit:contain;
`
const HoverPointer = styled.div`

:hover {
  cursor: pointer;
}
`
const ContainerDialog = styled.div<{ color: string }>`
  max-height: 650px;
  background-color:${p => p.color + '1A'};
  overflow: hidden;
  overflow-y:auto;
`
const defaultVideoImage = async () => {
  const response = await fetch(videoDefaultThumbnail);
  const data = await response.blob();
  const metadata = {
    type: 'image/svg+xml'
  };

  return new File([data], "default.svg", metadata);
}

const imageFromFrame = (videoFile, frameTimeInSeconds = 1) => {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement('canvas')
    const video = document.createElement('video')
    const source = document.createElement('source')
    const context = canvas.getContext('2d')
    const urlRef = URL.createObjectURL(videoFile)

    video.style.display = 'none'
    canvas.style.display = 'none'

    source.setAttribute('src', urlRef)
    video.setAttribute('crossorigin', 'anonymous')

    video.appendChild(source)
    document.body.appendChild(canvas)
    document.body.appendChild(video)

    if (!context) {
      console.log(`Couldn't retrieve context 2d`)
      resolve(null)
      video.remove()
      canvas.remove()  
      return
    }

    video.currentTime = frameTimeInSeconds
    video.load()

    video.addEventListener('loadedmetadata', function () {
      canvas.width = video.videoWidth
      canvas.height = video.videoHeight
    })

    video.addEventListener('loadeddata', function () {
      context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight)
      canvas.toBlob((blob) => {
        if (!blob) {
          resolve(null)
          video.remove()
          canvas.remove()  
          return
        }

        resolve(new File([blob], 'thumbnail', {type: 'image/png'}))
      
        URL.revokeObjectURL(urlRef)
        video.remove()
        canvas.remove()
      }, 'image/png')
    })
  })
}

const RenderSurveyMessage = ({ setNumOfSurveyStart }: { setNumOfSurveyStart: (item: number) => void }) => {
  return <SurveyList
    setNumOfSurveyStart={setNumOfSurveyStart}
  />
}


const RenderRegularMessage = observer(({ handleUpload, openMessageWarning }: { handleUpload: any, openMessageWarning: any }) => {
  const { t } = useTranslation(['MessageDialog'])
  const { messages, groups, newMessage, modulesStore } = useStore()
  const file = newMessage.files
  const groupColor = groups.currentGroup?.group.description?.color || theme.color.black
  const updateMessage = newMessage?.currentUserMessage?.message.id != 0
  const videoModule = modulesStore.communityFeatures.find(el => el.featureName === 'Video')
  return <Container>
               
    {file === undefined ? <Dropzone
      onDropRejected={() => openMessageWarning(t`notFotmat`)}
      onDrop={handleUpload}
      multiple={false}
      accept={['application/pdf', 'image/png', 'image/jpg', 'image/jpeg'].concat(videoModule && videoModule.enabled ? ['video/mp4','video/mpeg','video/webm'] : [])}
    >
      {({ getRootProps, getInputProps }) => (
       <Tooltip title={t`fileOptions`}><DropArea color={groupColor} {...getRootProps()}>
          <input {...getInputProps()} />
          <DropAreaContent>
            <DropAreaIconsContainer>
              {videoModule && videoModule.enabled && <DropzoneIcon namespace='mekome' name='video' size={32} />}
              <DropzoneIcon namespace='mekome' name='galleryRoundGreen' size={34} />
              <DropzoneIcon namespace='mekome' name='documentRoundBlue' size={34} />
            </DropAreaIconsContainer>
            <Text textcolor={theme.color.black + 'A1'}>{t`dragFiles`}</Text>
          </DropAreaContent>
        </DropArea></Tooltip> 
      )}
    </Dropzone>:
      <div style={{ flex: 1 }}>
        {!updateMessage && <StyledButton
          onClick={() => newMessage.clearFiles()}>
          <Close style={{ color: 'black' }} />
        </StyledButton>}
        <HoverPointer>
          {(file?.type === 'PDF') && 
            <PdfPreview height={900} file={file?.url} />}
          {(file?.type === 'VIDEO') && 
            <VideoViewer 
              widthClass=".rc-md-editor" 
              fileUrl={file?.local ? window.URL.createObjectURL(file?.url) : file?.url} 
              fileType={file?.local ? file?.url?.type : null}
              thumbnail={newMessage.videoThumbhail ? newMessage.videoThumbhail.local ? URL.createObjectURL(newMessage.videoThumbhail.url) : newMessage.videoThumbhail.url : null }
            />}
          {(file?.type === 'IMAGE') &&   
            <StyledImage
              onClick={() => messages.openViewerModal('image', file?.local ? URL.createObjectURL(file.url) : file?.url)}
              src={ file?.local ? URL.createObjectURL(file.url) : file?.url}
            />
          }
        </HoverPointer>
      </div>
    }
  </Container>
});

const MessageDialog: FC<Props> = () => {
  const { t, i18n } = useTranslation(['MessageDialog'])
  const { t: t2 } = useTranslation('app')
  const { messages, groups, newMessage, orgs, modulesStore } = useStore()
  const [input, setInput] = useState('')
  const updateMessage = newMessage?.currentUserMessage?.message.id != 0
  const [openWarning, setOpenWarning] = useState(false)
  const [warningMessage, setWarningMessage] = useState('')
  const groupColor = groups.currentGroup?.group.description?.color || theme.color.black
  const supportCallType = groups.currentGroup?.group?.type === GroupType.SUPPORT
  const videoModule = modulesStore.communityFeatures.find(el => el.featureName === 'Video')

  useEffect(() => {
    setInput(newMessage.messageText || '')
  }, [newMessage?.currentUserMessage]) 

  const handleUpload = async (file: File[]) => {
    if (!file || file.length === 0) {
      return
    }

    const typePdf = ['application/pdf']
    const typeVideo = ['video/mp4', 'video/x-msvideo', 'video/mpeg', 'video/ogg', 'video/webm']
    const typeImage = ['image/jpeg', 'image/png']

    let validTypes = [...typeImage, ...typePdf]
    
    if (videoModule && videoModule.enabled) {
      validTypes = [...typeVideo, ...validTypes]
    }

    if (!validTypes.includes(file[0]?.type)) {
      openMessageWarning(videoModule && videoModule.enabled ? t`notFotmatWithVideo` : t`notFotmat`)
      return
    }

    if (file[0]?.size > MAX_PDF_SIZE && file[0]?.type === 'application/pdf') {
      openMessageWarning(t`fileTooBig`)
      return
    }

    if (file[0]?.size > MAX_VIDEO_SIZE && typeVideo.includes(file[0].type)) {
      openMessageWarning(`גודל מקסימלי של וידאו 30M`)
      return
    }

    if (typePdf.includes(file[0].type)) {
      newMessage.setFiles(file[0], 'PDF', true)
    } else if(typeImage.includes(file[0].type)) {
      newMessage.setFiles(file[0], 'IMAGE', true)
    } else {
      newMessage.videoThumbhail = undefined

      try {
        const thumbnail = await imageFromFrame(file[0])
        if (thumbnail === null) {
          const defaultThumbnail = await defaultVideoImage()
          newMessage.setVideoThumbnail(defaultThumbnail, `IMAGE`, true)
          newMessage.setFiles(file[0], `VIDEO`, true)
        } else {
          newMessage.setVideoThumbnail(thumbnail, `IMAGE`, true)
          newMessage.setFiles(file[0], `VIDEO`, true)
        }  
      } catch(e) {
        const defaultThumbnail = await defaultVideoImage()
        newMessage.setVideoThumbnail(defaultThumbnail, `IMAGE`, true)
        newMessage.setFiles(file[0], `VIDEO`, true)
      }
    }
  }

  const handleOk = () => {
    messages.closeNewMessageDialog()
    newMessage.createMessage()
  }

  const onClose = () => {
    newMessage.clearAll()
    messages.closeNewMessageDialog()
  }

  const [numOfSurveyStart, setNumOfSurveyStart] = useState(0)

  const isDisabled = () => {
    if (!input.length && !newMessage.files && !newMessage.surveyAnswers) {
      return true
    }
    if (newMessage.surveyAnswers && newMessage.surveyAnswers.length === 0) {
      return true
    }
    if (updateMessage && newMessage.surveyAnswers && newMessage?.surveyAnswers?.length < numOfSurveyStart) {
      return true
    }
  }

  const openMessageWarning = (text: string) => {
    setOpenWarning(true)
    setWarningMessage(text)
  }

  const showLocationRequestCheckbox = () => {
    return ((modulesStore.isEnabledLocationFeature && userIsDirectorOrOperator()) || userIsSuperAdmin()) && groups.currentGroup?.group.type !== GroupType.SUPPORT
  }

  function handleEditorChange({ html, text }: { html: any, text: string }) {
    if (text !== undefined) {
      newMessage.updateMessageText(text)
    }
    setInput(text)
  }

  return (
    <StyledDialog
      // scroll='paper'
      open={messages.isModalOpen}
      // onClose={onClose}
      // onBackdropClick={()=>{}}
      fullWidth
    // maxWidth='md'

    >
      <MessagesHeader
        textVarient={'black'}
        backColor={groupColor + '1A'}
        secondText={updateMessage ? t`MessageDialog:messagesEditMessage` : supportCallType ? t`serviceCall:new_service_call` :t`messagesNewMessage`}
        mainText={supportCallType ? `${(orgs.currentOrg?.name || '')} ${t`serviceCall:services`} | ${groups?.currentGroup?.group?.name} `:groups?.currentGroup?.group?.name}
        onClose={onClose}
      />

      <ContainerDialog color={groupColor}>
        <DialogContent>
          {showLocationRequestCheckbox() && 
          <>
          <Switch
            color="primary"
            value={newMessage.locationRequest}
            onChange={(e) => {
              newMessage.updateLocationRequestToggle(e.target.checked)
            }}
            checked={newMessage.locationRequest}
          />בקשת מיקום בקריאת הודעה  
          </>}
          <Space />
          {!supportCallType && <MessagesDateTimeAccordion />}
          <Space />
          {/* {!deliveryTimeExpanded && (
          <Appear> */}
          <RichTextEditor
            value={input}
            onChange={handleEditorChange}
            placeholder={messages.messageDialogMode === MessageDialogMode.Survey ? t('Survey:question') : t`messageBody`}
          />
          <Space height={1} />
          {!newMessage.surveyAnswers && <RenderRegularMessage
            handleUpload={handleUpload}
            openMessageWarning={openMessageWarning}
          />}
          {newMessage.surveyAnswers && <RenderSurveyMessage
            setNumOfSurveyStart={setNumOfSurveyStart} />}
          {/* </Appear>
        )} */}
        </DialogContent>
      </ContainerDialog>
      <DialogActions style={{ backgroundColor: groupColor + '1A' }}>
        <ConfirmButton disabled={isDisabled()} endIcon={<SendIcon />} onClick={handleOk}>{t2(updateMessage ? 'save' : 'send')}</ConfirmButton>
      </DialogActions>
      <WarningDialog
        isOpen={openWarning}
        okAction={() => setOpenWarning(false)}
        cancelAction={() => setOpenWarning(false)}
        singleText={warningMessage}
      />
    </StyledDialog>
  )
}

export default observer(MessageDialog)
