import axios, { AxiosInstance } from 'axios'
import Cookies from 'js-cookie'
import { orgs, snackBar } from 'store'
import userStore from 'store/user.store'
import type { UserMessage, User, CsvUser } from 'utils/models'
import {
  ID,
  UserGroup,
  MessageOp,
  CreateCommunityReq,
  UserGroupCreateReq,
  MessageResponseOp,
  GroupType,
  ModuleEnum,
  ProviderEnum,
  CommunityUpdateReq,
  AlarmMessage
} from 'utils/types'
import { intToBool } from 'utils/converts'
import { newGuid } from 'utils/generates'
import { ModuleType } from 'utils/types'
import { RelateUserGroupOp, OperationType } from 'utils/operation'
import { truncate } from 'utils/strings'

export const endpoint = {
  newAlarmMessage: () => `/sapi/deviceLocation/alert`,
  updateLocation: (messageId: number) => `/sapi/deviceLocation/alert/${messageId}`,
  getDeviceLocation: () => `/sapi/deviceLocation`,
  getGroupUsers: (groupId: number) => `/sapi/userinfo/group/${groupId}`,
  getGroupMessages: (groupId = 0, pageNumber = 1, pageSize = 20 ) => `/sapi/messages/${groupId}/?pageNumber=${pageNumber}&pagesize=${pageSize}`,
  getGroups: (communityId: number, otherUserId: string | number = '') => `/sapi/groups/community/${communityId}/${otherUserId}`,
  getLastUserLocation: (communityId: number) => `/sapi/deviceLocation/${communityId}`,
  getLocationLog: (communityId: number) => `/sapi/deviceLocation/log/${communityId}`,
  
  getBrands: () => `/sapi/brands`,

  getCommunitySettings: (communityId: number) => `/sapi/communitysettings/${communityId}`,
  getCommunities: () => `/sapi/communities`,
  updateCommunities: (communityId: number) => `/sapi/communities/${communityId}`,
  manageCommunity: (communityId: number) => `/sapi/communities/manage/${communityId}`,
  updateCommunity: (communityId: number) => `sapi/communities/features/${communityId}`,
  getCommunitiesUsers: (communityId = 0) => `/sapi/userinfo/community/${communityId}`,
  getCommunityMessages: (communityId = 0) => `/sapi/messages/community/${communityId}/`,
  getCommunityModules: (communityId: string | number = '') => `/sapi/modules/${communityId}/true`,
  getCommunityDirectorModules: (communityId: string | number = '') => `sapi/managemodules/director/${communityId}`,
  manageCommunityModules: (moduleId: number | string = '') => `/sapi/managemodules/${moduleId}`,
  manageCommunityModulesByDirector: (moduleId: number | string = '', enabled: boolean) => `sapi/managemodules/status/${moduleId}/${enabled}`, 
  getOpenSupportCalls: (communityId = 0) => `/sapi/groups/supportcalls/${communityId}`,
  getSupportGroups: (communityId: number, otherUserId: string | number = '') => `/sapi/groups/support/${communityId}/${otherUserId}`,
  setUserCommunity: (communityId: number, otherUserId: number | null = null, enable?: boolean, showInAlphon?: boolean) => (
    `/sapi/userinfo/community/${communityId}/?otherUserId=${otherUserId}${enable !== undefined ? `&enable=${enable}` : ''}${showInAlphon !== undefined ? `&showInAlphon=${showInAlphon}` : ''}`
  ),
  login: () => '/api/users/login',
  logout: () => '/api/users/logout/3',
  verify: () => '/api/users/verify',
  refreshToken: () => '/api/users/auth/V-13.3.6-WMC/3',

  getMessageResponses: (messageId: number, date = '') => `/sapi/messages/response/${messageId}/${date}`,
  getGroupSendingProviders: () => `/sapi/GroupSendingProvider`,
  updateGroupSendingProviders: (id) => `/sapi/GroupSendingProvider/${id}`,
  updateOrgSendingProviders: (id) => `/sapi/CommunitySendingProviders/${id}`,
  getCommunitySendingProviders: () => `/sapi/CommunitySendingProviders`,

  openModule: (moduleId: number) => `/sapi/modules/open/${moduleId}`,
  clickModule: (moduleId: number, communityId: number) => `/sapi/modules/click/${moduleId}/${communityId}`,
  shareModule: (moduleId: number, communityId: number) => `/sapi/modules/share/${moduleId}/${communityId}`,
  resetModule: (moduleId: number, communityId: number) => `/sapi/modules/resetmoduledata/${moduleId}/${communityId}`,

  paymentLogin: (communityId: number) => `/sapi/payments/otp1/${communityId}`,
  paymentVerify: (communityId: number, pinCode: number) => `/sapi/payments/otp2/${communityId}/${pinCode}`,
  getPayment: (communityId: number) => `/sapi/payments/${communityId}/`,
  downloadPayment: (communityId: number, fileUrl = false) => `/sapi/payments/download/${communityId}/${fileUrl}`,
  resendPayment: (communityId: number) => `/sapi/payments/resend/${communityId}/`,
  forgetPayment: (communityId: number) => `/sapi/payments/forget/${communityId}/`,

  getProviders: () => `/sapi/providers`,
  getCommunityProvider: (providerId: number) => `/sapi/providers/community/${providerId}`,
  updateCommunityProvider: (providerId: number, communityId: number) => `/sapi/providers/${providerId}/community/${communityId}`,

  deleteProvider: (providerId: number, communityId: number) => `/sapi/providers/${providerId}/community/${communityId}`,
  setGroupProvider: (groupId: number, providerId: number) => `/sapi/groups/${groupId}/provider/${providerId}`,
  deleteGroupProvider: (groupId: number) => `/sapi/groups/${groupId}/provider`,
  deleteUserPushTokens: (userId: number) => `/sapi/pushtoken/user/${userId}`,

  getAssociatedGroups: () => '/sapi/entities/',
  createAssociatedGroups: () => `/sapi/Entities`,
  updateAssociatedGroups: (entityId: number) => `/sapi/Entities/${entityId}`,
  getAssociatedLabels: (entityId: number) => `/sapi/entities/labels/${entityId}`,
  getEntityLabelLinks: (entityId) => `/sapi/EntityLabels/entity/${entityId}`,
  getAllLabels: () => `/sapi/labels/entity/`,
  getAssociatedLabelsV2: (entityId: number) => `/sapi/labels/entity/${entityId}`,
  getAssociatedUsers: (entityId: number) => `/sapi/Entities/user/${entityId}`,
  createEntityUser: () => `/sapi/entities/user`,
  editEntityUser: (id) => `/sapi/Entities/user/${id}`, 
  deleteEntityUser: (entityId: number, userId: number) => `/sapi/Entities/${entityId}/user/${userId}`,
  createLabelAssociatedEntity: (entityId) => `/sapi/Groups/7016/labels/${entityId}`,
  createLabel: () => `/sapi/Labels`,
  linkLabelWithEntity: () => `/sapi/EntityLabels`,
  unlinkLabelWithEntity: (id) => `/sapi/EntityLabels/${id}`,
  editEntityLabel: (labelId) => `/sapi/labels/${labelId}`,

  createCommunity: () => '/sapi/communities/',

  createGroup: () => '/sapi/groups/',
  updateGroup: (groupId: number) => `/sapi/groups/${groupId}`,
  deleteGroupCommunity: (groupId: number, communityId: number) => `/sapi/groups/${groupId}/community/${communityId}`,
  updateGroupUsers: (groupId: number) => `/sapi/userinfo/bulk/group/${groupId}`,
  updateUserGroups: (userId: number) => `/sapi/userinfo/bulk/group/user/${userId}`,
  updateGroupLabels: (groupId: number, mcEntityId: number) => `/sapi/groups/${groupId}/labels/${mcEntityId}`,

  createUser: () => '/sapi/userinfo/',
  updateUser: (userId: number) => `/sapi/userinfo/${userId}`,
  createUsers: () => '/sapi/userinfo/bulk',

  deleteUserCommunity: (communityId: number, userId: number) => `/sapi/userinfo/community/${communityId}/user/${userId}`,
  updateUsersCommunityRelate: (communityId: number) => `/sapi/userinfo/bulk/community/${communityId}`,
  updateUserCommunityRelate: (communityId: number, userId: number) => `/sapi/userinfo/community/${communityId}/user/${userId}`,

  sendMessage: (groupId: number, schedule: boolean, isSupport?: boolean) => `/sapi/messages/${isSupport ? 'support/' : ''}${groupId}/${schedule}`,
  updateMessage: (messageId: number) => `/sapi/messages/${messageId}`,
  responseMessage: (messageId: number) => `/sapi/messages/response/message/${messageId}`,
  messageDetails: (messageId: number) => `/sapi/messages/response/${messageId}`,
  responseMessages: () => '/sapi/messages/response/bulk',
  responseGroupMessages: (groupId: number) => `/sapi/messages/response/group/${groupId}`,
  deleteMessage: (id: number) => `/sapi/messages/${id}`,

  signEula: (userId: number) => `/sapi/legal/sign/${userId}`,
  uploadIsraelId: (brandId: number) => `/sapi/userinfo/bulk/id/${brandId}`
}

const mapGroupsDescription = (groups: Array<any>, groupType: GroupType, isCommunityDirector = false) => {
  if (groups?.length > 0) {
    const groupsMap = groups.map<any>((g: any) => {
      return ({
        group: {
          community_id: g.community_id,
          id: isCommunityDirector ? -1 : g.id,
          description: /^{"/.test(g.description) ? JSON.parse(g.description) : g.description,
          name: g.name,
          icon: g.icon,
          members: g.members,
          orgainzers: g.orgainzers,
          group_is_member: g.group_is_member != null ? g.group_is_member : false,
          group_is_subscriber: g.group_is_subscriber != null ? g.group_is_subscriber : false,
          group_is_favourite: g.group_is_favourite != null ? g.group_is_favourite : false,
          group_is_sender: g.group_is_sender != null ? g.group_is_sender : false,
          duplex: g.duplex,
          is_organizer: g.is_organizer,
          parent_id: g.parent_id,
          providerId: g.providerId || null,
          providerName: g.providerName || null,
          sendSMS: g.sendSMS || false,
          sendEmail: g.sendEmail || false,
          allowShare: g.allowShare || false,
          type: groupType
        },
        community: g.community_id,
        is_favourite: g.is_favourite,
        is_organizer: g.is_organizer,
        is_sender: g.is_sender,
        is_subscriber: g.is_subscriber,
        is_member: g.is_member,
        user: g.user_id,
        parent_id: g.parent_id
      })
    })
    return groupsMap
  }
  return []
}

const PROTOCOL_VERSION = 'V-12.0'
class Api {

  constructor(secure: AxiosInstance) {
    this.secure = secure
    this.jwtToken = () => userStore.jwtToken
  }

  jwtToken: () => string

  secure: AxiosInstance

  newFetcher = async (URL: string, method: 'PATCH' | 'POST' | 'GET' | 'PUT' | 'DELETE' = 'POST', data: any, additionalHeaders: { [a: string]: string } = {}, iteration: number = 0): Promise<any> => {
    if (iteration >= 2) {
      return
    }
    const isFormData = data instanceof FormData
    const headers: any = {}
    if (this.jwtToken()) {
      headers['Authorization'] = `Bearer ${this.jwtToken() || ''}`;
    }
    if (!isFormData) {
      headers['Content-Type'] = 'application/json';
    }
    Object.entries(additionalHeaders).forEach(el => {
      headers[el[0]] = el[1];
    })
    let body: string | undefined | FormData = data ? (data instanceof FormData ? data : JSON.stringify(data)) : undefined;
    body = (body === '{}') ? undefined : body
    try {
      if (method === 'GET' || method === 'DELETE') {
        const response = await this.secure[method.toLowerCase() as 'get'](URL, { ...(body ? { params: data } : {}), headers, withCredentials: true });
        return response.data;
      } else {
        const response = await this.secure[method.toLowerCase() as 'post'](URL, body, { headers, withCredentials: true });
        return response.data;
      }
    } catch (e) {
      const err = e as any;
      const { errorDescription, errors } = err?.response?.data || {}
      const statusError = 'Error ' + err?.request?.status || 500;
      const fail = {
        success: false,
        statusCode: err?.request?.status || 500,
        status: err?.request?.status || 500,
        data: err?.response?.data,
        errorDescription: errorDescription || errors?.[0]?.errorDescription || err?.message || statusError,
        errors: errors || [err?.message || statusError],
        error: err?.message || statusError,
      }

      if (fail.status === 401) {
        if (iteration === 1) {
          return fail
        }
        if (URL != endpoint.refreshToken() && URL != endpoint.logout()) {
          const refreshRes = await this.refreshToken()
          if (refreshRes.success || refreshRes.statusCode === 200) {
            return await this.newFetcher(URL, method, data, additionalHeaders, ++iteration)
          }
        }
        return fail
      }
      return fail

    }
    
  }

  genericErrorText = 'קיימת בעיה בשרת כרגע. אנא נסו מאוחר יותר.'
  /**
   *
   * @param res api response
   * @param handleError whether there should be a snackbar displayed if theres an error
   */

  generateResponse = (res: any, handleError: boolean = true): { success: boolean; data: any; error: string } => {
    try {
      let data = typeof res === 'string' || res instanceof String ? JSON.parse(res?.toString()) : res
      if ((res.statusCode >= 400) || !data) {
          if (handleError) {
          snackBar.showError(data?.errorDescription)
        }
        return { success: false, data: undefined, error: data?.errors?.[0]?.errorDescription }
      } else {
        return { success: true, data: data?.data, error: data?.errorDescription }
      }
    }
    catch (e) {
      const err = e as string;
      console.log('genericResponse error ', err)
      return { success: false, data: undefined, error: err }
    }
  }

  getCode = async (phone: string) => {
    const data = {
      protocol: PROTOCOL_VERSION,
      Username: phone,
      RegistrationType: 3
    }
    const res = await this.newFetcher(endpoint.login(), 'POST', data)
    return this.generateResponse(res, false)

  }

  refreshToken = async (): Promise<any | undefined> => {
    const res = await this.newFetcher(endpoint.refreshToken(), "GET", '')
    if (res?.statusCode === 401 || res?.statusCode === 400) {
      userStore.cleenData()
      return res
    }
    const jwt = res?.data
      if (jwt && jwt.token) {
      userStore.login({ ...userStore.user, user_token: jwt.token})
    }
    return res
  }

  checkCode = async (code: string, tempToken: string): Promise<any | undefined> => {
    const data = {
      pinCode: code
    }
    try {
      const headers = { ['Authorization']: (tempToken && `Bearer ${tempToken}`) || '' }
      const res = await this.newFetcher(endpoint.verify(), 'POST', data, headers)
      // const res = await this.goApi.post('/api/users/verify', data, { headers, withCredentials: trues })
      const genericResponse = this.generateResponse(res, false);
      if (genericResponse?.error) {
        snackBar.showError(genericResponse?.error)
      }
      return genericResponse
    } catch (e) {
      return ({ success: false, statusCode: 401, errorDescription: 'error', error: 'error' })
    }
  }


  logout = async (): Promise<any> => {
    const result = await this.newFetcher(endpoint.logout(), 'GET', null)
    if (result.errors?.length) {
      snackBar.showError(result.errors[0]?.errorDescription)
    }
    return result;
  }

  getCommunities = async () => {
    const res = await this.newFetcher(endpoint.getCommunities(), 'GET', {})
    if (res?.status && res.status >= 400) {
      snackBar.showError(res.errorDescription)
      return []
    }
    return res || []
  }

  uploadIsraelId = async (data: any, brandId: number) => {
    const res = await this.newFetcher(endpoint.uploadIsraelId(brandId), 'PUT', data)
    if (res?.status && res.status >= 400) {
      snackBar.showError(res.errorDescription)
      return []
    }
    return res || []
  }
  createLabelAssociatedEntity = async (entityId, data: any) => {
    try {
      const res = await this.newFetcher(endpoint.createLabelAssociatedEntity(entityId), 'PUT', data)
    
      if (res?.status && res.status >= 400) {
        snackBar.showError(res.errorDescription)
        return []
      } 

      snackBar.showSuccess("עודכן בהצלחה")
      return res || []
    } catch (error) {
      snackBar.showError(this.genericErrorText)
      return []
    }
  }

  updateCommunity = async (id: number, data: object) => {
    const res = await this.newFetcher(endpoint.updateCommunity(id), 'PUT', data)
    if (res?.status && res.status >= 400) {
      snackBar.showError(res.errorDescription)
      return []
    }
    return res
  }

  sendAlarmMessage = async(data: AlarmMessage) => {
    const res = await this.newFetcher(endpoint.newAlarmMessage(), 'POST', data)

    if (res?.status && res.status >= 400) {
      snackBar.showError(res.errorDescription)
      return []
    }

    if (res.status == 'error') {
      snackBar.showError(res.errorDescription)
      return []
    }

    return res || []
  }

  updateDeviceLocation = async (messageId: number, data: object) => {
    const res = await this.newFetcher(endpoint.updateLocation(messageId), 'PUT', data)

    if (res?.status && res.status >= 400) {
      snackBar.showError(res.errorDescription)
      return []
    }

    if (res.status == 'error') {
      snackBar.showError(res.errorDescription)
      return []
    }

    return res || []
  }

  sendDeviceLocation = async(data: object) => {
    const res = await this.newFetcher(endpoint.getDeviceLocation(), 'PUT', data)

    if (res?.status && res.status >= 400) {
      snackBar.showError(res.errorDescription)
      return []
    }

    if (res.status == 'error') {
      snackBar.showError(res.errorDescription)
      return []
    }

    return res || []
  }

  updateCommunities = async (communityId: ID, data: CommunityUpdateReq) => {
    const res = await this.newFetcher(endpoint.updateCommunities(communityId), 'PUT', data)
    if (res?.status && res.status >= 400) {
      snackBar.showError(res.errorDescription)
      return []
    }
    return res || []
  }

  getCommunity = async (communityId: number) => {
    const res = await this.newFetcher(endpoint.manageCommunity(communityId), 'GET', {})
    if (res?.status && res.status >= 400) {
      snackBar.showError(res.errorDescription)
      return []
    }
    return res || []
  }

  getCommunitySettings = async (communityId: number) => {
    const res = await this.newFetcher(endpoint.getCommunitySettings(communityId), 'GET', {})
    if (res?.status && res.status == 403) {
    return []
    } else if (res?.status && res.status >= 400) {
      snackBar.showError(res.errorDescription)
      return []
    }
    return res || []
  }

  updateCommunitySettings = async (communityId: number, data: any) => {
    const res = await this.newFetcher(endpoint.getCommunitySettings(communityId), 'PUT', data)
    if (res?.status && res.status >= 400) {
      snackBar.showError(res.errorDescription)
      return []
    }

    snackBar.showSuccess("עודכן בהצלחה")
    return res || []
  }

  getBrands = async () => {
    const res = await this.newFetcher(endpoint.getBrands(), 'GET', {})
    if (res?.status && res.status >= 400) {
      snackBar.showError(res.errorDescription)
      return []
    }
    return res || []
  }

  getCommunitiesUsers = async (communityId: ID): Promise<User[]> => {
    const res = await this.newFetcher(endpoint.getCommunitiesUsers(communityId), 'GET', {})
    if (res?.status && res.status >= 400) {
      snackBar.showError(res.errorDescription)
      return []
    }
    return res.map((rawUser: any) => {
      let phones = []
      let emails = []
      try {
        if (rawUser.phones) {
          phones = JSON.parse(rawUser.phones)
        }
        if (rawUser.emails) {
          emails = JSON.parse(rawUser.emails)
        }
      } catch (e) {
        console.log('parse user issue ', e)
      }
      rawUser.is_operator = rawUser.is_operator || false
      rawUser.is_director = rawUser.is_director || false
      rawUser.is_showInAlphon = rawUser.is_showInAlphon || false
      rawUser.is_community_enabled = rawUser.is_community_enabled || false
      return { ...rawUser, phones: phones, emails: emails, app: rawUser.app ? rawUser.app : 'none' }
    })
  }

  getGroups = async (communityId: number, currentMemberId: number | string = '') => {
    const res = await this.newFetcher(endpoint.getGroups(communityId, currentMemberId), 'GET', {})
    if (res?.status && res.status >= 400) {
      snackBar.showError(res.errorDescription)
      return []
    }
    if (res.length) {
      return res.map((g: any) => {
        return ({
          group: {
            community_id: g.community_id,
            id: g.id,
            description: /^{"/.test(g.description) ? JSON.parse(g.description) : g.description,
            name: g.name,
            icon: g.icon,
            members: g.members,
            orgainzers: g.orgainzers,
            group_is_member: g.group_is_member != null ? g.group_is_member : false,
            group_is_subscriber: g.group_is_subscriber != null ? g.group_is_subscriber : false,
            group_is_favourite: g.group_is_favourite != null ? g.group_is_favourite : false,
            group_is_sender: g.group_is_sender != null ? g.group_is_sender : false,
            duplex: g.duplex,
            type: GroupType.REGULAR,
            mcEntity: g.mcEntity,
            mcEntityID: g.mcEntityID,
            providerId: g.providerId || null,
            providerName: g.providerName || null,
            sendSMS: g.sendSMS || false,
            sendEmail: g.sendEmail || false,
            allowShare: g.allowShare || false,
            groupLabels: g.groupLabels
          },
          is_favourite: g.is_favourite,
          is_organizer: g.is_organizer,
          is_sender: g.is_sender,
          is_subscriber: g.is_subscriber,
          is_member: g.is_member,

          user: g.user_id
        })
      })
    }
    return []
  }


  getGroupUsers = async (groupId: ID) /*: Promise<{ user_id: number, user_full_name: string, family_name: string, given_name: string, nick_name: string, is_favourite: boolean, is_member: boolean, is_organizer: boolean, is_sender: boolean, is_subscriber: boolean }[]> */ => {
    const res = await this.newFetcher(endpoint.getGroupUsers(groupId), 'GET', {})
    if (res?.status && res.status >= 400) {
      snackBar.showError(res.errorDescription)
      return []
    }
    return res || []
  }

  getGroupMessages = async (groupId: number): Promise<UserMessage[]> => {
    const res = await this.newFetcher(endpoint.getGroupMessages(groupId), 'GET', {})
    if (res?.status && res.status >= 400) {
      snackBar.showError(res.errorDescription)
      return []
    }
    //generate userGroups from response
    const userGroups: UserMessage[] = res?.map((rawMessage: any) => {
      let msg: UserMessage = {
        user: userStore.user?.user_id,
        status: rawMessage.status,
        response: rawMessage.response ? rawMessage.response : 0,
        message: {
          author_name: rawMessage.author_name,
          create_guid: rawMessage.create_guid,
          created: rawMessage.created ? new Date(rawMessage.created) : rawMessage.created,
          expiry: rawMessage.expiry ? new Date(rawMessage.expiry) : rawMessage.expiry,
          group: rawMessage.group_ref,
          id: rawMessage.id,
          msg_strategy: rawMessage.msg_strategy,
          payload: rawMessage.payload,
          received: rawMessage.received,
          schedule: rawMessage.schedule ? new Date(rawMessage.schedule) : rawMessage.schedule,
          senderId: rawMessage.author_ref,
          senderName: rawMessage.author_name,
          updated: rawMessage.updated ? new Date(rawMessage.updated) : rawMessage.updated,
          locationRequest: rawMessage.locationRequest ? rawMessage.locationRequest : false 
        }
      }
      return msg
    }) || []
    return userGroups
  }

  lazyloadGroupMessages = async ({ pageNumber, pageSize, groupId }: { pageNumber: number, pageSize: number, groupId: number }) => {
    try {
      const res = await this.newFetcher(endpoint.getGroupMessages(groupId, pageNumber, pageSize), 'GET', {})
      if (res?.status && res.status >= 400) {
        snackBar.showError(res.errorDescription || this.genericErrorText)
        return []
      }
      return res
    } catch (error) {
      snackBar.showError(this.genericErrorText)
    }
    return []
  }

  getCommunityMessages = async (communityId: number): Promise<UserMessage[]> => {
    const res = await this.newFetcher(endpoint.getCommunityMessages(communityId), 'GET', {});
    if (res?.status && res.status >= 400) {
      snackBar.showError(res.errorDescription)
      return []
    }
    return res?.map((rawMessage: any) => {
      let msg: UserMessage = {
        user: userStore.user?.user_id,
        status: rawMessage.status,
        response: rawMessage.response ? rawMessage.response : 0,
        message: {
          author_name: rawMessage.author_name,
          create_guid: rawMessage.create_guid,
          created: rawMessage.created ? new Date(rawMessage.created) : rawMessage.created,
          expiry: rawMessage.expiry ? new Date(rawMessage.expiry) : rawMessage.expiry,
          group: rawMessage.group_ref,
          id: rawMessage.id,
          msg_strategy: rawMessage.msg_strategy,
          payload: rawMessage.payload,
          received: rawMessage.received,
          schedule: rawMessage.schedule ? new Date(rawMessage.schedule) : rawMessage.schedule,
          senderId: rawMessage.author_ref,
          senderName: rawMessage.author_name,
          updated: rawMessage.updated ? new Date(rawMessage.updated) : rawMessage.updated,
          locationRequest: rawMessage.locationRequest ? rawMessage.locationRequest : false 
        }
      }
      return msg
    }) || []
  }

  deleteMessage = async (messageId: number) => {
    return await this.newFetcher(endpoint.deleteMessage(messageId), 'DELETE', {});
  }

  OpenModule = async (moduleId: number, communityId: number, infoType: ModuleEnum, providerEnum?: ProviderEnum, callback?: (any: any) => void, tryWhenInit?: boolean) => {
    try {
      const body = {
        communityId
      }
      const res = await this.newFetcher(endpoint.openModule(moduleId), 'GET', body);
      if (res?.status && res.status >= 400) {
        if (tryWhenInit) {
          return []
        }
        snackBar.showError(res.errors?.[0]?.errorDescription || res.errorDescription)
        return []
      }
      if (res.errors?.length === 0 && res.data != '') {
        switch (infoType) {
          case ModuleEnum.SLIKA:
            return res;
          case ModuleEnum.PORTAL:
            switch (providerEnum) {
              case ProviderEnum.MAOF:
                if (res.data) {
                  const cookieData = JSON.parse(res.data)
                  if ('ConcatToUrl' in cookieData && callback) {
                    callback(cookieData['ConcatToUrl'])
                  }
                }
                break;
              case ProviderEnum.MEKOME:
                if (callback) {
                  callback(res.data)
                }
                break;
              default:
                break;
            }
            break;
          default:
            break;
        }
      }
      return res;
    } catch (e) {
      console.log("response2")   
      console.log('error:', e)
    }
  }
  getBudget = async (moduleId: number, communityId: number, infoType: ModuleEnum, year?: number, month?: number) => {
    let res = null;
    if (year || month) {
      if (typeof orgs.currentOrgId !== 'number') {
        return;
      }
      const body = {
        year: year,
        month: month,
        ignoreOTPSave: true,
      }
      res = await this.newFetcher(endpoint.getPayment(orgs.currentOrgId), 'GET', body);
    } else {
      res = await this.OpenModule(moduleId, communityId, infoType, undefined, undefined, true)
    }
    if ((res?.status && res.status >= 400) || res?.errors?.length) {
      throw res;
    }
    try {
      const budget = JSON.parse(res.budget)
      const extraData = (res.extraData && JSON.parse(res.extraData)) || null
      if (budget?.data?.[0]) {
        budget.data[0].extraData = extraData
      }
      return budget?.data?.[0]
    } catch (e) {
      throw e;
    }
  }

  getFiles = async (year: number, month: number) => {
    if (typeof orgs.currentOrgId !== 'number') {
      return;
    }
    const body: any = {
        year: year,
        month: month,
        ignoreOTPSave: true,
        files: true
    }
    const res = await this.newFetcher(endpoint.getPayment(orgs.currentOrgId), 'GET', body);
    if ((res?.status && res.status >= 400) || res?.errors?.length) {
      throw new Error('Failed to load.')
    }
    return ({ fileDates: res.fileDates, files: res.files })
  }

  getFile = async (fileId: string) => {
    if (!orgs.currentOrgId) {
      return;
    }
    const res = await this.newFetcher(endpoint.downloadPayment(orgs.currentOrgId, true), 'GET', { fileId });
    if ((res?.status && res.status >= 400) || res?.errors?.length) {
      throw res
    }
    return res
  };

  getPos = async (year: number, month: number) => {
    if (!orgs.currentOrgId) {
      return;
    }
    const body: any = {
      files: false,
      year: year,
      month: month,
      isPos: true
    }
    const res = await this.newFetcher(endpoint.getPayment(orgs.currentOrgId), 'GET', body);
    if ((res?.status && res.status >= 400) || res?.errors?.length) {
      snackBar.showError(res.errorDescription)
      return { data: [] }
    }
    return JSON.parse(res.data);
  }

  createAssociatedGroups = async (data: any) => {
    try {
      const response = await this.newFetcher(endpoint.createAssociatedGroups(), "POST", {
        id: 0,
        name: data.name,
        smsCounter: parseInt(data.smsCounter),
        extraData: JSON.stringify(data.extraData),
        status: data.status
      })

      if (response?.status && response.status >= 400) {
        throw new Error(response.errorDescription)
      }

      return true

    } catch (error) {
      console.log('update community modules failed', error)
      snackBar.showError(this.genericErrorText)
      return false
    }
  }

  updateAssociatedGroups = async (entityId: number, data: any) => {
    try {
      const response = await this.newFetcher(endpoint.updateAssociatedGroups(entityId), "PUT", {
        id: entityId,
        name: data.name,
        smsCounter: parseInt(data.smsCounter),
        extraData: data.extraData,
        status: data.status
      })

      if (response?.status && response.status >= 400) {
        throw new Error(response.errorDescription)
      }

      return true
    } catch (error) {
      console.log('update community modules failed', error)
      snackBar.showError(this.genericErrorText)
      return false
    }
  }

  getAssociatedGroups = async () => {
    return await this.newFetcher(endpoint.getAssociatedGroups(), 'GET', {});
  }

  getAssociatedLabels = async (council = 1) => {
    return await this.newFetcher(endpoint.getAssociatedLabels(council), 'GET', {});
  }

  getAllLabels = async () => {
    return await this.newFetcher(endpoint.getAllLabels(), 'GET', {});
  }


  getAssociatedLabelsV2 = async (entityId) => {
    return await this.newFetcher(endpoint.getAssociatedLabelsV2(entityId), 'GET', {});
  }

  getAssociatedUsers = async (entityId) => {
    return await this.newFetcher(endpoint.getAssociatedUsers(entityId), 'GET', {});
  }

  getAssociatedLabelByEntity = async (entityId) => {
    return await this.newFetcher(endpoint.getAssociatedLabels(1), 'GET', {});
  }

  setAssociatedGroups = async (mcEntityId: number, groupId: number, labels: Array<string>) => {
    const res = await this.newFetcher(endpoint.updateGroupLabels(groupId, mcEntityId), 'PUT', labels);
    if ((res?.status && res.status >= 400) || res?.errors?.length) {
      snackBar.showError(res.errorDescription || this.genericErrorText)
    }
    return res
  }

  getCommunityDirectorModules  = async (communityId: number) => {
    try {
      const response = await this.newFetcher(endpoint.getCommunityDirectorModules(communityId), "GET", {})

      if (response?.status && response.status >= 400) {
        throw new Error(response.errorDescription)
      }
      return response

    } catch (error) {
      console.log('get community modules failed', error)
      snackBar.showError(this.genericErrorText)
    }
    return []
  }

  getCommunityModules = async (communityId: number) => {
    try {
      const response = await this.newFetcher(endpoint.getCommunityModules(communityId), "GET", {})

      if (response?.status && response.status >= 400) {
        throw new Error(response.errorDescription)
      }
      return response

    } catch (error) {
      console.log('get community modules failed', error)
      snackBar.showError(this.genericErrorText)
    }
    return []
  }

  deleteCommunityModules = async (id: number) => {
    try {
      const response = await this.newFetcher(endpoint.manageCommunityModules(id), "DELETE", {})
      if (response?.status && response.status >= 400) {
        throw new Error(response.errorDescription)
      }
    } catch (error) {
      console.log('DELETE community modules failed', error)
      snackBar.showError(this.genericErrorText)
    }
  }
  addCommunityModules = async (module: ModuleType) => {
    try {
      const response = await this.newFetcher(endpoint.manageCommunityModules(), "POST", {
        module_id: module.module_id,
        community_ref: module.community_ref,
        type: module.type,
        directorPermission: module.directorPermission,
        enable: module.enable,
        external_url: module.external_url,
        title: module.title,
        description: module.description,
        icon_color: module.icon_color,
        icon_text: module.icon_text,
        icon_name: module.icon_name,
        icon_text_settings: module.icon_text_settings,
        org_id: module.org_id,
        provider: module.provider,
        information: module.information,
        data: module.data
      })

      if (response?.status && response.status >= 400) {
        throw new Error(response.errorDescription)
      }
      return response;
    } catch (error) {
      console.log('update community modules failed', error)
      snackBar.showError(this.genericErrorText)
    }
    return {}
  }

  updateCommunityModuleByDirector = async (module: ModuleType) => {
    try {
      const response = await this.newFetcher(endpoint.manageCommunityModulesByDirector(module.module_id, module.enable), "PATCH", {})

      if (response?.status && response.status >= 400) {
        throw new Error(response.errorDescription)
      }

    } catch (error) {
      console.log('update community modules failed', error)
      snackBar.showError(this.genericErrorText)
    }
  }

  updateCommunityModules = async (module: ModuleType) => {
    try {
      const response = await this.newFetcher(endpoint.manageCommunityModules(module.module_id), "PUT", {
        module_id: module.module_id,
        community_ref: module.community_ref,
        type: module.type,
        directorPermission: module.directorPermission,
        enable: module.enable,
        external_url: module.external_url,
        title: module.title,
        description: module.description,
        icon_color: module.icon_color,
        icon_text: module.icon_text,
        icon_name: module.icon_name,
        icon_text_settings: module.icon_text_settings,
        org_id: module.org_id,
        provider: module.provider,
        share: module.share,
        shareEmail: module.shareEmail,
        information: module.information,
        data: module.data
      })

      if (response?.status && response.status >= 400) {
        throw new Error(response.errorDescription)
      }

    } catch (error) {
      console.log('update community modules failed', error)
      snackBar.showError(this.genericErrorText)
    }
  }


  sendMessage = async (requestOps: MessageOp[], blobFile: File | File[] | null = null, groupType: GroupType) => {
    try {
      const user = userStore.user

      const formData = new FormData()
      formData.append('json', JSON.stringify({
        protocol: PROTOCOL_VERSION,
        type: "application/json",
        myId: user?.user_id,
        // myToken: user?.user_token,
        version: user?.version ? user.version : 0,
        requestOps: requestOps
      }))

      if (Array.isArray(blobFile)) {
        for (let x = 0; x < blobFile.length; x++) {
          formData.append("file[]", blobFile[x], x == 1 ? "video.mp4" : "image.png");
        }
      }
      else if (blobFile) {
        formData.append("file", blobFile, "image.png")
      }

      const requestOptions = requestOps[0];
      const res = await this.newFetcher(endpoint.sendMessage(requestOptions.group, !!requestOptions.schedule, requestOptions.op === OperationType.CREATE_SUPPORT_MESSAGE), 'POST', formData)
      let data;
      if (typeof res === 'string') {
        try {
          data = JSON.parse(res)
        } catch (e) {
          data = { results: [{ status: 'fail', errorDescription: 'Fail' }] }
        }
      } else {
        data = res;
      }
      const error = !data || data?.results?.find((el: any) => el.status?.toLowerCase?.() != 'ok')
      const isError = !data || data?.status && data.status >= 400 || error;
      if (isError) {
        snackBar.showError(data?.errorDescription || this.genericErrorText)
      }
      return { success: isError ? false : true, data: data }

    }
    catch (e) {
      console.log(e)
    }
  }

  deleteUserPushToken = async (userId: number) => {
    await this.newFetcher(endpoint.deleteUserPushTokens(userId), 'DELETE', {})
  }

  messageDetails = async (messageId: number) => {
    const res = await this.newFetcher(endpoint.messageDetails(messageId), 'GET', {})
    if (res?.status && res.status >= 400) {
      snackBar.showError(res.errorDescription)
      return []
    }
    return res || []
  }

  responseMessage = async (msg: MessageResponseOp | MessageResponseOp[]) => {
    if (Array.isArray(msg)) {
      return await this.newFetcher(endpoint.responseMessages(), "PUT", msg)
    }
    return await this.newFetcher(endpoint.responseMessage(msg.messageId as number), "PUT", msg)
  }

  responseGroupMessages = async (groupId: number, status: number) => {
    return await this.newFetcher(endpoint.responseGroupMessages(groupId), "PUT", {
      response: 0,
      status
    })
  }
  
  getMessageResponses = async (messageId: number) => {
    const res = await this.newFetcher(endpoint.getMessageResponses(messageId), 'GET', {})
    if (res?.status && res.status >= 400) {
      snackBar.showError(res.errorDescription)
      return []
    }
    return res || []
  }

  getOpenSupportCalls = async (communityId: number) => {
    try {
      const res = await this.newFetcher(endpoint.getOpenSupportCalls(communityId), 'GET', {})
      if (res?.status && res.status >= 400) {
        snackBar.showError(res.errorDescription)
        return [];
      }
      return mapGroupsDescription(res, GroupType.SUPPORT)
    }
    catch (error) {
      console.log('getOpenSupportCalls', error)
    }
    return []
  }

  getLastUserLocation = async (communityId: number, queryParams: any) => {
    try {
      const queryStr = new URLSearchParams(queryParams).toString()
      const res = await this.newFetcher(`${endpoint.getLastUserLocation(communityId)}?${queryStr}`, 'GET', {})
      if (res?.status && res.status >= 400) {
        snackBar.showError(res.errorDescription)
        return [];
      }
      return res
    }
    catch (error) {
      console.log('getOpenSupportCalls', error)
    }
    return []
  }

  getLocationLog = async (communityId: number, queryParams: any) => {
    try {
      const queryStr = new URLSearchParams(queryParams).toString()
      const res = await this.newFetcher(`${endpoint.getLocationLog(communityId)}?${queryStr}`, 'GET', {})
      if (res?.status && res.status >= 400) {
        snackBar.showError(res.errorDescription)
        return [];
      }
      return res
    }
    catch (error) {
      console.log('getOpenSupportCalls', error)
    }
    return []
  }

  getSupportGroups = async (communityId: number, isDirector: boolean): Promise<UserGroup[] | undefined> => {
    try {
      const res = await this.newFetcher(endpoint.getSupportGroups(communityId), 'GET', {})
      if (res?.status && res.status >= 400) {
        snackBar.showError(res.errorDescription)
        return;
      }

      return mapGroupsDescription(res, GroupType.SUPPORT, isDirector)
    }
    catch (error) {
      console.log('getSupportGroups error: ', error)
    }
    return []
  }

  updateMessage = async (msg: MessageOp) => {
    return await this.newFetcher(endpoint.updateMessage(msg.message as number), "PUT", msg)
  }

  createCommunity = async (name: string) => {
    const data: CreateCommunityReq = {
      id: 1,
      name,
      guid: newGuid(),
    }
    return await this.newFetcher(endpoint.createCommunity(), "POST", data)
  }

  createGroup = async (userGroup: UserGroup) => {
    const data: UserGroupCreateReq = {
      community: userGroup.group.community_id,
      guid: newGuid(),
      name: userGroup.group.name,
      description: userGroup.group.description === '' ? '' : JSON.stringify(userGroup.group.description),
      icon: userGroup.group.icon || null,
      organizer: !!userGroup.is_organizer,
      sender: !!userGroup.group.group_is_sender,
      subscriber: !!userGroup.group.group_is_subscriber,
      member: !!userGroup.group.group_is_member,
      favourite: !!userGroup.group.group_is_favourite,
      hide: !!userGroup.is_hide,
      sms: !!userGroup.group.sendSMS,
      duplex: userGroup.group.duplex,
      id: 1,
      SendSMS: !!userGroup.group.sendSMS,
      SendEmail: !!userGroup.group.sendEmail,
      allowShare: !!userGroup.group.allowShare,
    }
    return await this.newFetcher(endpoint.createGroup(), "POST", data)
  }

  updateGroup = async (userGroup: UserGroup) => {
    const data = {
      community: userGroup.group.community_id,
      name: userGroup.group.name,
      description: (userGroup.group.description === '' || (userGroup.group.description as string)?.match?.(/^((\\*"*)*)$/g)) ? '' : JSON.stringify(userGroup.group.description),
      icon: userGroup.group.icon || null,
      sender: !!userGroup.group.group_is_sender,
      subscriber: !!userGroup.group.group_is_subscriber,
      member: !!userGroup.group.group_is_member,
      favourite: !!userGroup.group.group_is_favourite,
      hide: !!userGroup.is_hide,
      sms: !!userGroup.group.sendSMS,
      duplex: userGroup.group.duplex,
      SendSMS: !!userGroup.group.sendSMS,
      SendEmail: !!userGroup.group.sendEmail,
      allowShare: !!userGroup.group.allowShare,
    }
    return await this.newFetcher(endpoint.updateGroup(userGroup.group.id), "PUT", data)
  }

  removeGroup = async (data: any) => {
    return await this.newFetcher(endpoint.deleteGroupCommunity(data.group, data.community), "DELETE", {})
  }

  createUser = async (user: User, communityId: number) => {
    const data = {
      givenName: user.given_name,
      familyName: user.family_name,
      nickName: user.nick_name,
      guid: newGuid(),
      israeliID: /\*+\d+/ig.test(user.israeliID) ? null : user.israeliID,
      phones: user.phones,
      emails: user.emails,
      communityId: communityId,
      sendSMS: user.sendSMS,
      sendEmail: user.sendEmail
    }
    return await this.newFetcher(endpoint.createUser(), "POST", data)
  }

  updateUser = async (communityId: number, user: User, userId?: number) => {
    const convertedUser: any = {
      givenName: user.given_name,
      familyName: user.family_name,
      nickName: user.nick_name,
      phones: user.phones,
      israeliID: /\*+\d+/ig.test(user.israeliID) ? null : user.israeliID,
      emails: user.emails,
      communityId: communityId,
      sendSMS: user.sendSMS,
      sendEmail: user.sendEmail
    }
    return await this.newFetcher(endpoint.updateUser(userId ? userId : user.user_id), 'PUT', convertedUser)
  }

  createUsers = async (users: CsvUser[], communityId: number): Promise<{ createdUsers: number[], errorMessages: string[][] }> => {
    const data: any[] = []
    users.forEach((csvUser: CsvUser) => {
      data.push({
        israeliID: csvUser.user.israeliID,
        givenName: csvUser.user.given_name,
        familyName: csvUser.user.family_name,
        nickName: csvUser.user.nick_name,
        guid: newGuid(),
        phones: csvUser.user.phones,
        emails: csvUser.user.emails,
        communityId: communityId,
        sendSMS: csvUser.user.sendSMS,
        sendEmail: csvUser.user.sendEmail
      })
    })
    return await this.newFetcher(endpoint.createUsers(), "POST", data)
  }

  deleteUserCommunity = async (data: { community: number; user: number }) => {
    return await this.newFetcher(endpoint.deleteUserCommunity(data.community, data.user), "DELETE", {})
  }

  updateUserGroups = async (userGroups: UserGroup[], updateOrgenizer: boolean = false, isRemove = false) => {
    const isAdmin = (userGroup: UserGroup) => { return Boolean(intToBool(userGroup.is_organizer) || intToBool(orgs.currentOrg?.is_director || 0)) }

    if (!userGroups || userGroups.length === 0) { return }
    const reqOps = userGroups.map((userGroup) => {
      const requestOp: any = {
        op: OperationType.RELATED_USER_GROUP,
        guid: newGuid(),
        member: intToBool(userGroup.is_member),
        sender: intToBool(userGroup.is_sender),
        subscriber: intToBool(userGroup.is_subscriber),
        favourite: intToBool(userGroup.is_favourite),
        groupId: Math.abs(userGroup.group.id),
        userId: userGroup.user
      }
      if (intToBool(userGroup.is_sender)) {
        requestOp.sender = intToBool(userGroup.is_sender)
      }
      if (isAdmin(userGroup) && updateOrgenizer) {
        requestOp.organizer = isRemove ? false : intToBool(userGroup.is_organizer)
      }
      return requestOp
    })
    return await this.newFetcher(endpoint.updateUserGroups(reqOps[0].userId), 'POST', reqOps)
  }

  updateGroupUsers = async (userGroups: UserGroup[], updateOrgenizer: boolean = false, isRemove = false) => {
    const isAdmin = (userGroup: UserGroup) => { return Boolean(intToBool(userGroup.is_organizer) || intToBool(orgs.currentOrg?.is_director || 0)) }

    if (!userGroups || userGroups.length === 0) { return }
    const reqOps = userGroups.map((userGroup, index) => {
      const requestOp: any = {
        op: OperationType.RELATED_USER_GROUP,
        id: index,
        member: intToBool(userGroup.is_member),
        sender: intToBool(userGroup.is_sender),
        subscriber: intToBool(userGroup.is_subscriber),
        favourite: intToBool(userGroup.is_favourite),
        groupId: Math.abs(userGroup.group.id),
        userId: userGroup.user
      }
      if (intToBool(userGroup.is_sender)) {
        requestOp.sender = intToBool(userGroup.is_sender)
      }
      if (isAdmin(userGroup) && updateOrgenizer) {
        requestOp.organizer = isRemove ? false : intToBool(userGroup.is_organizer)
      }
      return requestOp
    })
    return await this.newFetcher(endpoint.updateGroupUsers(reqOps[0].groupId), 'POST', reqOps)
  }

  resetModuleData = async (moduleId: number, communityId: number, userId?: number) => {
    try {
      const res = await this.newFetcher(endpoint.resetModule(moduleId, communityId), 'PUT', {
        // year: 2022,
        // month: 8,
        communityId
      });
      if (res?.status && res.status >= 400) {
        snackBar.showError(res.errorDescription)
        return []
      }
      return res;
    } catch (error) {
      snackBar.showError(this.genericErrorText)
      console.log('update community modules failed', error)
    }
  }

  updateUsers(communityId: number, users: User[]) {
    let responses: any = []
    users.forEach((user: User, index: number) => responses[index] = this.updateUser(communityId, user, undefined))
    return responses
  }
  
  updateUserCommunity = async (user: User, communityId: number, isAdmin: boolean) => {
      const data: any = {
        director: isAdmin,
        operator: isAdmin,
      }
      return await this.newFetcher(endpoint.updateUserCommunityRelate(communityId, user.user_id), 'POST', data)
  }
  setUserCommunity = async (communityId: number, enable: boolean) => {
    const res = await this.newFetcher(endpoint.setUserCommunity(communityId, null, enable), 'PUT', {})
    if (res?.status && res.status >= 400) {
      snackBar.showError(res.errorDescription || this.genericErrorText)
      return;
    }
    return res
  }
  updateUsersCommunityRelate = async (usersIds: number[], communityId: number) => {

    const membersRequestData = usersIds.map((user_id) => {
      const requestOp: any = {
        director: false,
        operator: false,
        enabled: true,
        userId: user_id,
        showInAlphon: true,
      }
      return requestOp
    })
    if (membersRequestData != null && membersRequestData.length > 0) {
      return await this.newFetcher(endpoint.updateUsersCommunityRelate(communityId), 'POST', membersRequestData)
    }
  }

  updateUserAlphon = async (communityId: number, showInAlphon: boolean, otherUserId?: number) => {
    const res = await this.newFetcher(endpoint.setUserCommunity(communityId, otherUserId, undefined, showInAlphon), 'PUT', {})
    if (res?.status && res.status >= 400) {
      snackBar.showError(res.errorDescription || this.genericErrorText)
    }
  }

  budgetOtp1 = async (communityId: number, phone: string, id: string, saveData: boolean) => {
    const body = {
      phoneNumber: phone,
      israeliId: id,
      saveData,
      // Id: id,
    }
    const res = await this.newFetcher(endpoint.paymentLogin(communityId), 'POST', body);
    if (res?.status && res.status >= 400) {
      throw res?.errors
    }
    return res
  }
  budgetVerify = async (communityId: number, pinCode: number) => {
    const res = await this.newFetcher(endpoint.paymentVerify(communityId, pinCode), 'GET', {});
    if ((res?.status && res.status >= 400) || res?.errors?.length) {
      throw res?.errors
    }
    return res
  }
  budgetForget = async (communityId: number) => {
    const res = await this.newFetcher(endpoint.forgetPayment(communityId), 'GET', {});
    if ((res?.status && res.status >= 400) || res?.errors?.length) {
      throw res
    }
    return res;
  }
  budgetResend = async (communityId: number) => {
    const res = await this.newFetcher(endpoint.resendPayment(communityId), 'GET', {});
    if ((res?.status && res.status >= 400) || res?.errors?.length) {
      throw res
    }
    return res?.errors
  }
  signEula = async () => {
    return await this.newFetcher(endpoint.signEula(userStore.user.user_id), 'POST', {});
  }
  getProviders = async () => {
    return await this.newFetcher(endpoint.getProviders(), 'GET', {});
  }
  getSelectedProvider = async (orgId: number) => {
    return await this.newFetcher(endpoint.getCommunityProvider(orgId), 'GET', {});
  }
  updateCommunityProvider = async (providerId: number) => {
    const communityId = orgs.currentOrgId;
    if (!communityId) {
      return;
    }
    return await this.newFetcher(endpoint.updateCommunityProvider(providerId, communityId), 'PUT', {});
  }
  setGroupProvider = async (groupId: number, providerId: number) => {
    return await this.newFetcher(endpoint.setGroupProvider(groupId, providerId), 'PUT', {});
  }
  deleteGroupProvider = async (groupId: number) => {
    return await this.newFetcher(endpoint.deleteGroupProvider(groupId), 'DELETE', {});
  }
  deleteCommunityProvider = async (communityId: number, providerId: number) => {
    return await this.newFetcher(endpoint.deleteProvider(providerId, communityId), 'DELETE', {});
  }

  removeEntityUser = async (entityId: number, userId: number) => {
    try {
      const res =  await this.newFetcher(endpoint.deleteEntityUser(entityId, userId), 'DELETE', {});

      if (res?.status && res.status >= 400) {
        snackBar.showError(res.errorDescription)
        return []
      }

      snackBar.showSuccess("עודכן בהצלחה")
      return res

    } catch (error) {
      snackBar.showError(this.genericErrorText)
      return []
    }
  }

  createEntityUser = async (entityId, data) => {
    const [givenName, ...restNames] = data.name.trim().replace(/(\s{2,})+/, ' ').split(' ')
    const reqData = {
      guid: newGuid(),
      emails: data.emails,
      phones: data.phones,
      givenName: givenName ? givenName : '',
      entityId: entityId,
      nickName: '',
      familyName: Array.isArray(restNames) && restNames.length > 1 ? restNames.join(' ') : '',
    }

    try {
      const res = await this.newFetcher(endpoint.createEntityUser(), "POST", reqData)
      if (res?.status && res.status >= 400) {
        if (res?.data && res.data?.errorMessages && Array.isArray(res.data?.errorMessages) && res.data?.errorMessages.length > 0) {
          const error = JSON.parse(res.data?.errorMessages[0]) 
          //snackBar.showError(error.description)
          return { resErr: true, error: error.description }
        } else {
          //snackBar.showError(res.errorDescription)
          return { resErr: true, error: res.errorDescription }
        }
      }

      snackBar.showSuccess("עודכן בהצלחה")
      return res

    } catch (error) {
      //snackBar.showError(this.genericErrorText)
      return { resErr: true, error: this.genericErrorText }
    }
  }

  unlinkLabelWithEntity  = async (id) => {
    try {
      const res = await this.newFetcher(endpoint.unlinkLabelWithEntity(id), "DELETE", {})
      
      if (res?.status && res.status >= 400) {
        snackBar.showError(res.errorDescription)
        return null
      }

      snackBar.showSuccess("עודכן בהצלחה")
      return res

    } catch (error) {
      snackBar.showError(this.genericErrorText)
      return []
    }
  } 

  linkLabelWithEntity = async (entityId, labelId) => {
    try {
      const res = await this.newFetcher(endpoint.linkLabelWithEntity(), "POST", {
        entityId, 
        labelId, 
        status: 1
      })
      
      if (res?.status && res.status >= 400) {
        snackBar.showError(res.errorDescription)
        return null
      }

      snackBar.showSuccess("עודכן בהצלחה")
      return res

    } catch (error) {
      snackBar.showError(this.genericErrorText)
      return []
    }
  } 

  createLabel = async (data) => {
    try {
      const res = await this.newFetcher(endpoint.createLabel(), "POST", data)

      if (res?.status && res.status == 422) {
        snackBar.showError(res.errorDescription)
        return null
      }
      
      if (res?.status && res.status >= 400) {
        snackBar.showError(res.errorDescription)
        return null
      }

      snackBar.showSuccess("עודכן בהצלחה")
      return res

    } catch (error) {
      snackBar.showError(this.genericErrorText)
      return []
    }
  }

  editEntityUser = async (user, data, entityId) => {
    const [givenName, ...restNames] = data.name.trim().replace(/(\s{2,})+/, ' ').split(' ')
    const reqData = {
      guid: newGuid(),
      emails: Array.isArray(data.emails) && data.emails.length == 1 && data.emails[0].trim() == '' ? [] : data.emails,
      phones: Array.isArray(data.phones) && data.phones.length == 1 && data.phones[0].trim() == '' ? [] : data.phones,
      userId: user.userId,
      givenName: givenName ? givenName : '',
      entityId: entityId,
      nickName: '',
      familyName: Array.isArray(restNames) && restNames.length > 1 ? restNames.join(' ') : '',
    }

    try {
      const res = await this.newFetcher(endpoint.editEntityUser(user.userId), "PUT", reqData)
      
      if (res?.status && res.status >= 400) {
        if (res?.data && res.data?.errorMessages && Array.isArray(res.data?.errorMessages) && res.data?.errorMessages.length > 0) {
          const error = JSON.parse(res.data?.errorMessages[0]) 
          //snackBar.showError(error.description)
          return { resErr: true, error: error.description }
        } else {
          //snackBar.showError(res.errorDescription)
          return { resErr: true, error: res.errorDescription }
        }
      }

      snackBar.showSuccess("עודכן בהצלחה")
      return res

    } catch (error) {
      //snackBar.showError(this.genericErrorText)
      //return null
      return { resErr: true, error: this.genericErrorText }
    }
  }

  editEntityLabel = async (labelId, data) => {
    try {
      const res = await this.newFetcher(endpoint.editEntityLabel(labelId), "PUT", data) 
      
      if (res?.status && res.status == 422) {
        snackBar.showError(res.errorDescription)
        return null
      }

      if (res?.status && res.status >= 400) {
        snackBar.showError(res.errorDescription)
        return []
      }

      snackBar.showSuccess("עודכן בהצלחה")
      return res

    } catch (error) {
      snackBar.showError(this.genericErrorText)
      return []
    }
  }

  deleteOrgSendingProvider = async (id) => {
    try {
      const res = await this.newFetcher(endpoint.updateOrgSendingProviders(id), "DELETE", {})
      
      if (res?.status && res.status >= 400) {
        snackBar.showError(res.errorDescription)
        return null
      }

      snackBar.showSuccess("עודכן בהצלחה")
      return res

    } catch (error) {
      snackBar.showError(this.genericErrorText)
      return []
    }
  }
  
  deleteGroupSendingProvider = async (id) => {
    try {
      const res = await this.newFetcher(endpoint.updateGroupSendingProviders(id), "DELETE", {})
      
      if (res?.status && res.status >= 400) {
        snackBar.showError(res.errorDescription)
        return null
      }

      snackBar.showSuccess("עודכן בהצלחה")
      return res

    } catch (error) {
      snackBar.showError(this.genericErrorText)
      return []
    }
  }

  createOrgSendingProvider = async (data) => {
    try {
      const res = await this.newFetcher(endpoint.getCommunitySendingProviders(), "POST", data) 
      
      if (res?.status && res.status == 422) {
        snackBar.showError(res.errorDescription)
        return null
      }

      if (res?.status && res.status >= 400) {
        snackBar.showError(res.errorDescription)
        return []
      }

      snackBar.showSuccess("עודכן בהצלחה")
      return res

    } catch (error) {
      snackBar.showError(this.genericErrorText)
      return []
    }
  }

  updateOrgSendingProvider = async (id, data) => {
    try {
      const res = await this.newFetcher(endpoint.updateOrgSendingProviders(id), "PUT", data) 
      
      if (res?.status && res.status == 422) {
        snackBar.showError(res.errorDescription)
        return null
      }

      if (res?.status && res.status >= 400) {
        snackBar.showError(res.errorDescription)
        return []
      }

      snackBar.showSuccess("עודכן בהצלחה")
      return res

    } catch (error) {
      snackBar.showError(this.genericErrorText)
      return []
    }
  }

  createGroupSendingProvider = async (data) => {
    try {
      const res = await this.newFetcher(endpoint.getGroupSendingProviders(), "POST", data) 
      
      if (res?.status && res.status == 422) {
        snackBar.showError(res.errorDescription)
        return null
      }

      if (res?.status && res.status >= 400) {
        snackBar.showError(res.errorDescription)
        return []
      }

      snackBar.showSuccess("עודכן בהצלחה")
      return res

    } catch (error) {
      snackBar.showError(this.genericErrorText)
      return []
    }
  }

  updateGroupSendingProvider = async (id, data) => {
    try {
      const res = await this.newFetcher(endpoint.updateGroupSendingProviders(id), "PUT", data) 
      
      if (res?.status && res.status == 422) {
        snackBar.showError(res.errorDescription)
        return null
      }

      if (res?.status && res.status >= 400) {
        snackBar.showError(res.errorDescription)
        return []
      }

      snackBar.showSuccess("עודכן בהצלחה")
      return res

    } catch (error) {
      snackBar.showError(this.genericErrorText)
      return []
    }
  }

  getGroupSendingProviders = async (communityId) => {
    try {
      const res = await this.newFetcher(endpoint.getGroupSendingProviders(), 'GET', {
        communityId
      });
      if (res?.status && res.status >= 400) {
        snackBar.showError(res.errorDescription)
        return []
      }
      return res;
    } catch (error) {
      snackBar.showError(this.genericErrorText)
    }
    return []
  }

  getCommunitySendingProviders = async (communityId) => {
    try {
      const res = await this.newFetcher(endpoint.getCommunitySendingProviders(), 'GET', {
        communityId
      });
      if (res?.status && res.status >= 400) {
        snackBar.showError(res.errorDescription)
        return []
      }
      return res;
    } catch (error) {
      snackBar.showError(this.genericErrorText)
    }
    return []
  }

  shareInformation = async ( { moduleId, communityId}: { moduleId: number, communityId: number}) => {
    try {
      const res = await this.newFetcher(endpoint.shareModule(moduleId, communityId), 'GET', {
        // year: 2022,
        // month: 8,
        communityId
      });
      if (res?.status && res.status >= 400) {
        snackBar.showError(res.errorDescription)
        return []
      }
      return res;
    } catch (error) {
      snackBar.showError(this.genericErrorText)
    }
    return []
  }

  getEntityLabelLinks = async (entityId) => {
    try {
      const res = await this.newFetcher(endpoint.getEntityLabelLinks(entityId), 'GET', {});
      
      if (res?.status && res.status >= 400) {
        snackBar.showError(res.errorDescription)
        return []
      }

      return res;
    } catch (error) {
      snackBar.showError(this.genericErrorText)
      return []
    }
    return []
  }

  sendClickAfterExpand = async ( { moduleId, communityId}: { moduleId: number, communityId: number}) => {
    try {
      const res = await this.newFetcher(endpoint.clickModule(moduleId, communityId), 'GET', {
        // year: 2022,
        // month: 8,
        communityId
      });
      if (res?.status && res.status >= 400) {
        snackBar.showError(res.errorDescription)
        return []
      }
      return res;
    } catch (error) {
      snackBar.showError(this.genericErrorText)
    }
    return []
  }
}

  

const API_MEKOMI_BASE_URL = process.env.REACT_APP_API_MEKOMI_BASE_URL
const X_ZUMO_APPLICATION = process.env.REACT_APP_X_ZUMO_APPLICATION

const sApi = axios.create({
  baseURL: `${API_MEKOMI_BASE_URL}`,
  headers: {
    'X-ZUMO-APPLICATION': X_ZUMO_APPLICATION,
    'X-ZUMO-FEATURES': 'AJ'
  }
})

const api = new Api(sApi)
export default api
