import React, {FC} from 'react'
import videojs from 'video.js';
import 'video.js/dist/video-js.css';

const VideoViewer: FC<any> = ({ fileUrl, fileType, thumbnail, widthClass, onLoaded = null, padding = 0 }) => {
  const videoRef = React.useRef(null);
  const playerRef = React.useRef(null);

  const onReady = (player) => {
    playerRef.current = player;

    player.on('waiting', () => {
      console.log('player is waiting');
    });

    player.on('dispose', () => {
      console.log('player will dispose');
    });

    player.on('playerresize', () => {
      if (onLoaded !== null) {
          onLoaded()
      }
    })
  }

  const initPlayer = (fType) => {

    if (!videoRef.current) {
      return;
    }

    let options = {
      height: '320',
      autoplay: false,
      controls: true,
      enableSmoothSeeking: true,
      sources: [{
        src: fileUrl,
        type: fType
      }]
    }

    if (thumbnail) {
      options['poster'] = thumbnail
    }

    if (!playerRef.current) {
      const videoElement = document.createElement("video-js");

      videoElement.classList.add('vjs-big-play-centered');
      videoRef.current.appendChild(videoElement);

      const player = playerRef.current = videojs(videoElement, options, () => {
        videojs.log('player is ready');
        onReady && onReady(player);

        if (thumbnail) {
          fetch(thumbnail)
            .then(response => response.headers.get('content-type') == 'image/svg+xml' ? response.text() : null)
            .then(svg => {
              if (!svg) {
                return;
              }

              if (!svg.includes("svg id=\"default_thumb\"")) {
                return
              }

              const note = videoElement.querySelector('.vjs-poster img');
              
              if (note) {
                note.style.setProperty('object-fit', 'cover', 'important');
              }
            })
        }

        const resizeVideo = () => {
          const box = document.querySelector(widthClass);

          if (box && player.el()) {
            const dem = getComputedStyle(player.el())

            const parentWidth = parseInt(box.parentNode.offsetWidth)
            const maxBoxMaxWidthPercentage = parseInt(getComputedStyle(box).maxWidth)  
            const boxWidthOffset = widthClass == '.rc-md-editor' ? box.offsetWidth :  parseInt(parentWidth * (maxBoxMaxWidthPercentage/100))
  
            if (boxWidthOffset > 0 && dem?.width && parseInt(dem.width) > boxWidthOffset) {
              let width = boxWidthOffset - padding*2
              let height = Math.round(parseInt(dem.height) * width / parseInt(dem.width))
  
              player.width(Math.round(width));
              player.height(Math.round(height));
            }
          }
        }

        resizeVideo()
        window.addEventListener('resize', resizeVideo);
      });

      player.addClass('vjs-matrix');

    // You could update an existing player in the `else` block here
    // on prop change, for example:
    } else {
      const player = playerRef.current;
      player.poster(thumbnail);
      player.autoplay(options.autoplay);
      player.src(options.sources);
    }
  }

  React.useEffect(() => {
    if (!fileType && fileUrl.includes("blob:")) {
      initPlayer('video/mp4')
      return;
    }

   if (!fileType) {
    fetch(fileUrl, {
      method: 'HEAD'
    }).then(reponse => {
      const sourceVideoType = reponse.headers.get('Content-Type');

      if (sourceVideoType) {
        initPlayer(sourceVideoType.replace("/*", "/mp4"))
      } 
    })  
   } else {
    initPlayer(fileType)
   }
  }, [fileUrl, fileType, videoRef]);

  // Dispose the Video.js player when the functional component unmounts
  React.useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (   
  <div style={{ display: 'flex', justifyContent: 'center', background: 'black' }} data-vjs-player>
    <div ref={videoRef} />
  </div>)
}

export default VideoViewer;