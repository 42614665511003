import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Close } from '@material-ui/icons'
import { Row, Text, theme } from 'ui'
import { observer } from 'mobx-react'
import useStore from 'store'
import TextField from '@material-ui/core/TextField'
import UserHideFromAlphoneLine from '../UserHideFromAlphoneLine'
import { memberErrorsType } from 'store/member.store'
import { Switch } from '@material-ui/core'

const StyledTextField = styled(TextField)`
    width:100%;
    margin:10px;
    direction:rtl;
    @media (max-width: 768px) {
      width: unset;
    }
`
const Form = styled.div`
    flex:1.4;
    flex-direction:column;
    display:flex;
    margin:5px;
    position:relative;
    justify-content:flex-start;
    align-items:center;
    height:700px;
    `
const StyledUserConditional = styled.div`
    display: flex; 
    align-self: flex-start; 
    align-items: center;
    margin: 10px;
`

const StyledRow = styled(Row)`
    align-self: flex-start; 
`
const UserContactLine = ({ value, onChange, label, error, type }: { value: string, onChange: any, label: string, error: string | undefined, type: string }) => {
  const { uiStore, memberStore } = useStore()
  const currentMember = memberStore.getCurrentMemebr
  const updateUser = currentMember?.user_id != -1

  const onPressDelete = () => {
    if (updateUser) {
      uiStore.openWarningModal({
        text: 'אתה בטוח שברצונך למחוק  ?',
        secondText: value,
        okAction: () => {
          onChange('')
        }
      })
    } else {
      onChange('')
    }

  }

  return (
    <StyledTextField
      type={type}
      value={value}
      error={Boolean(error)}
      helperText={error}
      label={label}
      variant='outlined'
      onChange={(event) => onChange(event.target.value)}
      InputProps={{
        endAdornment: value != '' ? <Close style={{ color: theme.color.black + 99 }} onClick={onPressDelete} /> : <div />
      }}
    />

  )
}
const UserInputLine = ({ value, showDelete, onChange, onDelete, label, error }:
  { value: string, onChange: any, label: string, error: string | undefined, onDelete: any, showDelete: boolean }) => {

  return (
    <StyledTextField
      value={value}
      error={Boolean(error)}
      helperText={error}
      label={label}
      variant='outlined'
      onChange={onChange}
      InputProps={{
        endAdornment:
          showDelete ?
            <Close
              style={{ color: theme.color.black + 99 }}
              onClick={onDelete} /> : <div />
      }}
    />
  )
}
const UserConditional = ({ checked, handleChange, text }: { checked: boolean, handleChange: () => void, text: string }) => {
  return (
    <StyledUserConditional>
      <Text>{text}</Text>
      <Switch checked={checked} onChange={handleChange} name={text} color="primary" />
    </StyledUserConditional>
  )
}
const CreateUserForm = () => {
  const { memberStore } = useStore()
  const { t } = useTranslation('members')
  const currentMember = memberStore.getCurrentMemebr
  console.log('currentMember', currentMember)
  if (!currentMember) {
    return null
  }
  const updateUser = currentMember?.user_id != -1
  return (
    <Form>
      {updateUser && <UserHideFromAlphoneLine
        title={!currentMember?.is_showInAlphon ? t`hide_from_alphone_title` : t`show_from_alphone_title`}
        subTitle={!currentMember?.is_showInAlphon ? t`hide_from_alphone_sub_title` : t`show_from_alphone_sub_title`}
        checked={!currentMember?.is_showInAlphon} handleChange={() => memberStore.toggleUserAlphone()} />}
      <UserInputLine
        value={currentMember?.given_name}
        label={t`firstName`}
        onChange={(event: { target: { value: string } }) => memberStore.setMemberName(event.target.value)}
        onDelete={() => memberStore.setMemberName('')}
        showDelete={currentMember?.given_name != ''}
        error={memberStore.errors.get(memberErrorsType.GivenName)?.description}
      />
      <UserInputLine
        value={currentMember?.family_name}
        label={t`lastName`}
        onChange={(event: { target: { value: string } }) => memberStore.setMemberFamilyName(event.target.value)}
        onDelete={() => memberStore.setMemberFamilyName('')}
        showDelete={currentMember?.family_name != ''}
        error={memberStore.errors.get(memberErrorsType.FamilyName)?.description}
      />
      <UserInputLine
        value={currentMember?.israeliID}
        label={t`israeliID`}
        onChange={(event: { target: { value: string } }) => memberStore.setIsraeliID(event.target.value)}
        onDelete={() => memberStore.setIsraeliID('')}
        showDelete={currentMember?.israeliID != ''}
        error={memberStore.errors.get(memberErrorsType.israeliID)?.description}
      />
       <UserInputLine
        value={currentMember?.nick_name}
        label={t`nickName`}
        onChange={(event: { target: { value: string } }) => memberStore.setMemberNickName(event.target.value)}
        onDelete={() => memberStore.setMemberNickName('')}
        showDelete={currentMember?.nick_name != ''}
        error={memberStore.errors.get(memberErrorsType.NickName)?.description}
      />
      <UserContactLine
        type={'text'}
        value={currentMember?.phones[0] ? currentMember?.phones[0] : ''}
        onChange={(v: string) => memberStore.setPhoneNumber1(v.trim())}
        label={t`phone`}
        error={memberStore.errors.get(memberErrorsType.phoneNumber1)?.description}
      />
      <UserContactLine
        type={'text'}
        value={currentMember?.phones[1] ? currentMember?.phones[1] : ''}
        onChange={(v: string) => memberStore.setPhoneNumber2(v.trim())}
        label={t`phone`}
        error={memberStore.errors.get(memberErrorsType.phoneNumber2)?.description}
      />
      <UserContactLine
        type={'text'}
        value={currentMember?.emails[0] ? currentMember?.emails[0] : ''}
        onChange={(v: string) => memberStore.setEmail(v)}
        label={t`email`}
        error={memberStore.errors.get(memberErrorsType.email)?.description}
      />
      <StyledRow >
        <UserConditional checked={currentMember.sendSMS} text={t('groupDialog:send_sms')} handleChange={() => memberStore.toggleSendSms()} />
        <UserConditional checked={currentMember.sendEmail} text={t('groupDialog:send_email')} handleChange={() => memberStore.toggleSendEmail()} />
      </StyledRow>
    </Form>
  )
}


export default observer(CreateUserForm)
